<script>
import dayjs from '@/lib/dayjs'
export default {
  name: 'MixinFunc',
  methods: {
    dateFormat (date, defaultStr = '無') {
      if (!date) return defaultStr
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    },
  },
}
</script>
