import { classTicketStatusConfig } from '@/config/classTicket'
import { get } from 'lodash'

export const useClassTicket = () => {
  const classTicketStatusLabel = (data) => {
    return get(classTicketStatusConfig, `${data.status}.label`)
  }

  return { classTicketStatusLabel }
}
