<template>
  <el-dialog
    class="dialog"
    :title="title"
    v-bind="$attrs"
    width="450px"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p v-if="useExportTask" class="mb-[8px]">確認匯出後，請至下載中心查看任務與下載匯出檔案</p>
    <el-form :model="formData" label-position="top">
      <el-form-item v-if="!allRange" label="匯出範圍">
        <el-radio-group v-model="formData.allRange">
          <el-radio :label="true">全部範圍</el-radio>
          <el-radio :label="false">指定範圍</el-radio>
        </el-radio-group>

        <div>
          <el-date-picker
            v-if="!formData.allRange"
            v-model="formData.range"
            :type="timeSelectorType"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>
      </el-form-item>
      <el-form-item label="匯出格式">
        <el-select
          v-model="formData.format"
          style="width: 100%"
          placeholder="請選擇格式"
        >
          <el-option
            v-for="item in exportFormatOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <p v-if="info" class="text-base text-sub">{{ info }}</p>
    <template #footer>
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        type="primary"
        style="margin-left: 12px"
        :disabled="!formData.allRange && !formData.range"
        @click="$emit('export', {
          all: formData.allRange || allRange,
          range: formData.range,
        })"
      >
        確認
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { exportFormatOptions } from '@/config/export'
import { computed, reactive } from 'vue'

export default {
  name: 'ExportOptionsDialog',
  props: {
    allRange: {
      type: Boolean,
      default: false,
    },
    disabledTime: Boolean,
    title: {
      type: String,
      default: '匯出',
    },
    info: {
      type: String,
      default: '',
    },
    useExportTask: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const formData = reactive({
      allRange: true,
      range: null,
      format: 'excel',
    })
    const timeSelectorType = computed(() => {
      if (props.disabledTime) return 'daterange'
      return 'datetimerange'
    })
    return { exportFormatOptions, formData, timeSelectorType }
  },
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: left;
}

::v-deep .el-input {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-select {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
