export const classTicketUseRecordTypeConfig = {
  ADMIN_USE: { label: '後台使用', value: 'ADMIN_USE' },
  ADMIN_CANCEL: { label: '堂票補發', value: 'ADMIN_CANCEL' },
  ADMIN_BOOKED: { label: '後台預扣', value: 'ADMIN_BOOKED' }, // 手動測試用
  ADMIN_UNBOOKED: { label: '後台預扣取消', value: 'ADMIN_UNBOOKED' }, // 手動測試用
  ADMIN_CHECKED: { label: '後台核銷', value: 'ADMIN_CHECKED' }, // 手動測試
  SALES_USE: { label: '銷售使用', value: 'SALES_USE' },
  SALES_CANCEL: { label: '銷售補發', value: 'SALES_CANCEL' },
  CLIENT_USE: { label: '顧客使用', value: 'CLIENT_USE' },
  GIVE_USE: { label: '轉贈扣除', value: 'GIVE_USE' },
  APPOINTMENT_BOOKED: { label: '預約預扣', value: 'APPOINTMENT_BOOKED' },
  APPOINTMENT_UNBOOKED: { label: '預扣退回', value: 'APPOINTMENT_UNBOOKED' },
  APPOINTMENT_CHECKED: { label: '預扣使用', value: 'APPOINTMENT_CHECKED' },
  VOID_CANCEL: { label: '堂票作廢', value: 'VOID_CANCEL' },
  SYSTEM_OVERDUE: { label: '堂票過期', value: 'SYSTEM_OVERDUE' },
}
export const classTicketUseRecordOriginConfig = {
  system: { label: '系統', value: 'system' },
  client: { label: '前台使用', value: 'client' },
  admin: { label: '後台使用', value: 'admin' },
  sales: { label: '銷售模組使用', value: 'sales' },
  give: { label: '轉贈扣除', value: 'give' },
  eventPlaybook: { label: '系統行銷活動發放', value: 'eventPlaybook' },
  appointment: { label: '預約模組', value: 'appointment' },
}

export const servicePromiseStatusConfig = {
  noPromise: { label: '-', value: 'noPromise' },
  waiting: { label: '待價保', value: 'waiting' },
  promised: { label: '未核銷', value: 'promised' },
  used: { label: '已核銷', value: 'used' },
  expired: { label: '已到期核銷', value: 'expired' },
  cancelled: { label: '系統取消', value: 'cancelled' },
}

export const classTicketStatusConfig = {
  overdue: { label: '已過期', value: 'overdue' },
  open: { label: '可使用', value: 'open' },
  complete: { label: '使用完畢', value: 'complete' },
  cancel: { label: '已作廢', value: 'cancel' },
  booked: { label: '已預扣', value: 'booked' },
}
