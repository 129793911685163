<template>
  <el-dialog
    class="dialog"
    :title="title"
    :visible="true"
    :close-on-click-modal="false"
    width="40%"
    @close="$emit('close')"
  >
    <el-progress type="circle" :color="progressColor" :percentage="percentage" :status="progressStatus" />
    <div v-if="content">
      {{ content }}
    </div>
    <div v-else>
      <div v-if="inProgress">匯出中</div>
      <div v-if="!inProgress && !isError">匯出成功</div>
      <div v-if="!inProgress && isError">產生報表出現狀況！</div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'ExportDialog',

  props: {
    title: String,
    content: String,
    percentage: Number,
    inProgress: Boolean,
    isError: Boolean,
  },
  computed: {
    progressStatus () {
      if (this.isError) return 'exception'
      if (this.inProgress) return null
      return null
    },
    progressColor () {
      if (this.isError) return 'var(--danger)'
      return 'var(--primary-50)'
    },
  },

}
</script>

<style scoped lang="scss">
.dialog {
  text-align: center;
}
</style>
