import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'

export const useExportCenter = () => {
  const router = useRouter()
  const downloadRoute = computed(() => router.resolve({ name: 'SheetExportCenter' }).href )
  const messageOptions = computed(() => {
    return {
      type: 'success',
      dangerouslyUseHTMLString: true,
      message: `<p>匯出任務已建立，請至<a class="underline" href="${downloadRoute.value}">下載中心</a>查看任務與下載匯出檔案</p>`,
      duration: 0,
      showClose: true,
    }
  })
  return {
    messageOptions,
  }
}
