<template>
  <div>
    <p class="text-primary-100 font-bold text-normal mb-[10px] mt-[32px]">堂票使用總覽</p>
    <div class="classTicket-use-list">
      <el-table :data="[list]">
        <el-table-column
          prop="availableUseTimes"
          label="剩餘可用張數"
          align="center"
        />
        <!-- <el-table-column
          prop="usedTimes"
          label="已使用"
          align="center"
        /> -->
        <!-- <el-table-column
          prop="cancel"
          label="已取消"
          align="center"
        /> -->
        <el-table-column
          prop="giveTimes"
          label="已贈張數"
          align="center"
        />
        <el-table-column
          prop="availableTimes"
          label="總張數"
          align="center"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
export default defineComponent({
  name: 'ClassTicketUseList',
  props: {
    classTicketRecordSerials: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const list = computed(() => {
      return {
        availableUseTimes: get(props.data, 'availableUseTimes', 0),
        usedTimes: get(props.data, 'usedTimes', 0),
        cancel: get(props.classTicketRecordSerials, 'cancel', 0),
        giveTimes: get(props.data, 'giveTimes', 0),
        availableTimes: get(props.data, 'availableTimes', 0),
      }
    })
    return { list }
  },
})
</script>

<style scoped lang="scss"></style>
